import React, { useEffect } from 'react';
import { App } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { NavBar, ModuleNavBar, OvalLoading, AppErrorBoundry, useSub } from "../../components";
import { LinearProgress } from '@mui/material';
import { userActions, getCurrentUser, getAlert, AppActions, isProgress, isAccessor, isOrganizer, isAuditee, isAdmin, isArchitect, isLoggedOut } from "../../store";
import { NotAllowedScreen } from "./NotAllowed";

const MODULE_ID = "controller";
const SUFFIX = process.env.REACT_APP_DOMAIN_SUFFIX || '';
const ConsoleEndPoint = `https://console${SUFFIX}.${process.env.REACT_APP_MAIN_DOMAIN}`;

export const HomeScreen = (props) => {
  const { notification, modal } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getCurrentUser);
  const appAlert = useSelector(getAlert)
  const showProgress = useSelector(isProgress);
  const loggedOut = useSelector(isLoggedOut);
  const { pathname } = useLocation();
  const navigateToLogin = () => {
    let isLocalhost = process.env.REACT_APP_LOCALHOST,
      path = ConsoleEndPoint + '/login', moduleId = null;

    moduleId = isLocalhost ? 'localhost' : (!loggedOut ? MODULE_ID : null)
    if (moduleId) {
      path += '?source=' + moduleId;
    }
    window.location.assign(path);
  }
  const allowed = () => {
    return !(isOrganizer(user) || isAccessor(user) || isAuditee(user))
  }
  useEffect(() => {
    document.title = 'Controller | Dev';
    dispatch(userActions.validateToken());
  }, []);
  useEffect(() => {
    if (loggedOut) {
      navigateToLogin()
    }
  }, [loggedOut])
  useSub("SESSION_LOGOUT", () => {
    dispatch(userActions.logout());
  })
  useEffect(() => {
    if (appAlert) {
      if (appAlert.type === 'modal') {
        let modalConfig = { title: appAlert.message, content: appAlert.description, okText: 'OK', onOk: clearNotification }
        if (appAlert.severity === 'warning') {
          modal.warning(modalConfig)
        } else if (appAlert.severity === 'error') {
          modal.error(modalConfig)
        } else if (appAlert.severity === 'success') {
          modal.success(modalConfig)
        }
      } else {
        const config = { duration: 2, message: appAlert.message, onClose: clearNotification }
        if (appAlert.success === false) {
          notification.error(config)
        } else {
          notification.success(config)
        }
      }
    }
  }, [appAlert]);
  useEffect(() => {
    if (user === 'Error') {
      navigateToLogin()
    } else {
      // dispatch(docActions.fetchDepts())
      // dispatch(docActions.fetchDocTemplates())
      // dispatch(docActions.fetchDocTypes())
      // dispatch(docActions.fetchDocClassifications())
      // dispatch(docActions.fetchDocStatusList())
    }
  }, [user]);
  const clearNotification = (e, reason) => {
    if (reason !== 'clickaway') {
      dispatch(AppActions.setAlert(null));
    }
  }
  return (
    <div className='col w-100 h-100 screen o-hide'>
      {
        Boolean(user && user !== 'Error') ?
          allowed() ?
            <React.Fragment>
              <NavBar user={user} isAdmin={isAdmin(user)} isArchitect={isArchitect(user)} currentModule={MODULE_ID} />
              <div className='col flex-full o-hide' style={{ position: 'relative' }}>
                <div className='progress-cnt w-100'>
                  <LinearProgress classes={{ root: `api-progress c1E686D ${showProgress ? 'show' : ''}` }} color="inherit" />
                </div>
                <main className='col flex-full o-hide'>
                  <div className='row w-100 h-100 o-hide'>
                    <ModuleNavBar pathname={pathname} />
                    <AppErrorBoundry>
                      {props.children}
                    </AppErrorBoundry>
                  </div>
                </main>
              </div>

            </React.Fragment>
            :
            <NotAllowedScreen user={user} />
          :
          <OvalLoading className='col w-100 h-100' />
      }
    </div>
  )
}